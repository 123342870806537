// Project imports
import http from 'util/http'

// Action Types
const ADD_DASHBOARD_REQUEST = 'dashboard/ADD_DASHBOARD_REQUEST'
const ADD_DASHBOARD_SUCCESS = 'dashboard/ADD_DASHBOARD_SUCCESS'
const ADD_DASHBOARD_FAILURE = 'dashboard/ADD_DASHBOARD_FAILURE'

const GET_ALL_DASHBOARDS_REQUEST = 'sector/GET_ALL_DASHBOARDS_REQUEST'
const GET_ALL_DASHBOARDS_SUCCESS = 'sector/GET_ALL_DASHBOARDS_SUCCESS'
const GET_ALL_DASHBOARDS_FAILURE = 'sector/GET_ALL_DASHBOARDS_FAILURE'

const GET_SECTOR_PUBLIC_DASHBOARDS_REQUEST = 'sector/GET_SECTOR_PUBLIC_DASHBOARDS_REQUEST'
const GET_SECTOR_PUBLIC_DASHBOARDS_SUCCESS = 'sector/GET_SECTOR_PUBLIC_DASHBOARDS_SUCCESS'
const GET_SECTOR_PUBLIC_DASHBOARDS_FAILURE = 'sector/GET_SECTOR_PUBLIC_DASHBOARDS_FAILURE'

const GET_SECTOR_PRIVATE_DASHBOARDS_REQUEST = 'sector/GET_SECTOR_PRIVATE_DASHBOARDS_REQUEST'
const GET_SECTOR_PRIVATE_DASHBOARDS_SUCCESS = 'sector/GET_SECTOR_PRIVATE_DASHBOARDS_SUCCESS'
const GET_SECTOR_PRIVATE_DASHBOARDS_FAILURE = 'sector/GET_SECTOR_PRIVATE_DASHBOARDS_FAILURE'

const GET_USER_PUBLIC_DASHBOARDS_REQUEST = 'dashboard/GET_USER_PUBLIC_DASHBOARDS_REQUEST'
const GET_USER_PUBLIC_DASHBOARDS_SUCCESS = 'dashboard/GET_USER_PUBLIC_DASHBOARDS_SUCCESS'
const GET_USER_PUBLIC_DASHBOARDS_FAILURE = 'dashboard/GET_USER_PUBLIC_DASHBOARDS_FAILURE'

const GET_USER_PRIVATE_DASHBOARDS_REQUEST = 'dashboard/GET_USER_PRIVATE_DASHBOARDS_REQUEST'
const GET_USER_PRIVATE_DASHBOARDS_SUCCESS = 'dashboard/GET_USER_PRIVATE_DASHBOARDS_SUCCESS'
const GET_USER_PRIVATE_DASHBOARDS_FAILURE = 'dashboard/GET_USER_PRIVATE_DASHBOARDS_FAILURE'

const UPDATE_DASHBOARD_REQUEST = 'dashboard/UPDATE_DASHBOARD_REQUEST'
const UPDATE_DASHBOARD_SUCCESS = 'dashboard/UPDATE_DASHBOARD_SUCCESS'
const UPDATE_DASHBOARD_FAILURE = 'dashboard/UPDATE_DASHBOARD_FAILURE'

const DELETE_DASHBOARD_REQUEST = 'dashboard/DELETE_DASHBOARD_REQUEST'
const DELETE_DASHBOARD_SUCCESS = 'dashboard/DELETE_DASHBOARD_SUCCESS'
const DELETE_DASHBOARD_FAILURE = 'dashboard/DELETE_DASHBOARD_FAILURE'

const GET_EMBED_TOKEN_REQUEST = 'dashboard/GET_EMBED_TOKEN_REQUEST'
const GET_EMBED_TOKEN_SUCCESS = 'dashboard/GET_EMBED_TOKEN_SUCCESS'
const GET_EMBED_TOKEN_FAILURE = 'dashboard/GET_EMBED_TOKEN_FAILURE'

export const actions = {
  ADD_DASHBOARD_REQUEST,
  ADD_DASHBOARD_SUCCESS,
  ADD_DASHBOARD_FAILURE,

  GET_ALL_DASHBOARDS_REQUEST,
  GET_ALL_DASHBOARDS_SUCCESS,
  GET_ALL_DASHBOARDS_FAILURE,

  GET_SECTOR_PUBLIC_DASHBOARDS_REQUEST,
  GET_SECTOR_PUBLIC_DASHBOARDS_SUCCESS,
  GET_SECTOR_PUBLIC_DASHBOARDS_FAILURE,

  GET_SECTOR_PRIVATE_DASHBOARDS_REQUEST,
  GET_SECTOR_PRIVATE_DASHBOARDS_SUCCESS,
  GET_SECTOR_PRIVATE_DASHBOARDS_FAILURE,

  GET_USER_PUBLIC_DASHBOARDS_REQUEST,
  GET_USER_PUBLIC_DASHBOARDS_SUCCESS,
  GET_USER_PUBLIC_DASHBOARDS_FAILURE,

  GET_USER_PRIVATE_DASHBOARDS_REQUEST,
  GET_USER_PRIVATE_DASHBOARDS_SUCCESS,
  GET_USER_PRIVATE_DASHBOARDS_FAILURE,

  UPDATE_DASHBOARD_REQUEST,
  UPDATE_DASHBOARD_SUCCESS,
  UPDATE_DASHBOARD_FAILURE,

  DELETE_DASHBOARD_REQUEST,
  DELETE_DASHBOARD_SUCCESS,
  DELETE_DASHBOARD_FAILURE,

  GET_EMBED_TOKEN_REQUEST,
  GET_EMBED_TOKEN_SUCCESS,
  GET_EMBED_TOKEN_FAILURE
}

/* ACTION CREATORS */

// ADD DASHBOARD
function addDashboardRequest() {
  return { type: ADD_DASHBOARD_REQUEST }
}

export function addDashboardSuccess(response) {
  return {
    type: ADD_DASHBOARD_SUCCESS,
    response
  }
}

function addDashboardFailure(error) {
  return {
    type: ADD_DASHBOARD_FAILURE,
    error
  }
}

// GET ALL DASHBOARDS
function getAllDashboardsRequest() {
  return { type: GET_ALL_DASHBOARDS_REQUEST }
}

export function getAllDashboardsSuccess(response) {
  return {
    type: GET_ALL_DASHBOARDS_SUCCESS,
    response
  }
}

function getAllDashboardsFailure(error) {
  return {
    type: GET_ALL_DASHBOARDS_FAILURE,
    error
  }
}

// GET SECTOR PUBLIC DASHBOARDS
function getSectorPublicDashboardsRequest() {
  return { type: GET_SECTOR_PUBLIC_DASHBOARDS_REQUEST }
}

export function getSectorPublicDashboardsSuccess(response) {
  return {
    type: GET_SECTOR_PUBLIC_DASHBOARDS_SUCCESS,
    response
  }
}

function getSectorPublicDashboardsFailure(error) {
  return {
    type: GET_SECTOR_PUBLIC_DASHBOARDS_FAILURE,
    error
  }
}

// GET SECTOR PRIVATE DASHBOARDS
function getSectorPrivateDashboardsRequest() {
  return { type: GET_SECTOR_PRIVATE_DASHBOARDS_REQUEST }
}

export function getSectorPrivateDashboardsSuccess(response) {
  return {
    type: GET_SECTOR_PRIVATE_DASHBOARDS_SUCCESS,
    response
  }
}

function getSectorPrivateDashboardsFailure(error) {
  return {
    type: GET_SECTOR_PRIVATE_DASHBOARDS_FAILURE,
    error
  }
}

// GET USER PUBLIC DASHBOARDs
function getUserPublicDashboardsRequest() {
  return { type: GET_USER_PUBLIC_DASHBOARDS_REQUEST }
}

export function getUserPublicDashboardsSuccess(response) {
  return {
    type: GET_USER_PUBLIC_DASHBOARDS_SUCCESS,
    response
  }
}

function getUserPublicDashboardsFailure(error) {
  return {
    type: GET_USER_PUBLIC_DASHBOARDS_FAILURE,
    error
  }
}

// GET USER PRIVATE DASHBOARDs
function getUserPrivateDashboardsRequest() {
  return { type: GET_USER_PRIVATE_DASHBOARDS_REQUEST }
}

export function getUserPrivateDashboardsSuccess(response) {
  return {
    type: GET_USER_PRIVATE_DASHBOARDS_SUCCESS,
    response
  }
}

function getUserPrivateDashboardsFailure(error) {
  return {
    type: GET_USER_PRIVATE_DASHBOARDS_FAILURE,
    error
  }
}

// UPDATE
function updateDashboardRequest() {
  return { type: UPDATE_DASHBOARD_REQUEST }
}

export function updateDashboardSuccess(response) {
  return {
    type: UPDATE_DASHBOARD_SUCCESS,
    response
  }
}

function updateDashboardFailure(error) {
  return {
    type: UPDATE_DASHBOARD_FAILURE,
    error
  }
}

// DELETE
function deleteDashboardRequest() {
  return { type: DELETE_DASHBOARD_REQUEST }
}

export function deleteDashboardSuccess(response) {
  return {
    type: DELETE_DASHBOARD_SUCCESS,
    response
  }
}

function deleteDashboardFailure(error) {
  return {
    type: DELETE_DASHBOARD_FAILURE,
    error
  }
}

// GET EMBED TOKEN
function getEmbedTokenRequest() {
  return { type: GET_EMBED_TOKEN_REQUEST }
}

export function getEmbedTokenSuccess(response) {
  return {
    type: GET_EMBED_TOKEN_SUCCESS,
    response
  }
}

function getEmbedTokenFailure(error) {
  return {
    type: GET_EMBED_TOKEN_FAILURE,
    error
  }
}

/* THUNK ACTION CREATORS */
// ADD ACTION
export function addDashboard(values) {
  return async dispatch => {
    dispatch(addDashboardRequest())
    try {
      const response = await http({
        method: 'POST',
        url: '/dashboard/new',
        data: {
          name: values.dashboardName,
          report_id: values.reportId,
          workspace_id: values.workspaceId,
          platform: values.platformId,
          is_private: values.isPrivate,
          sector_id: values.sectorId,
          owner: values.ownerId,
          users: values.users
        }
      })
      dispatch(addDashboardSuccess(response))
      return response.status
    } catch (error) {
      dispatch(addDashboardFailure(error))
      return null
    }
  }
}

// GET ALL DASHBOARDS ACTION
export function getAllDashboards() {
  return async dispatch => {
    dispatch(getAllDashboardsRequest())
    try {
      const response = await http({
        method: 'GET',
        url: '/dashboards/all'
      })
      const { data } = response
      dispatch(getAllDashboardsSuccess(data))
      return data
    } catch (error) {
      dispatch(getAllDashboardsFailure(error))
      return null
    }
  }
}

// GET SECTOR PUBLIC DASHBOARDS ACTION
export function getSectorPublicDashboards(sectorId) {
  return async dispatch => {
    dispatch(getSectorPublicDashboardsRequest())
    try {
      const response = await http({
        method: 'GET',
        url: `/sector/${sectorId}/dashboards/public`
      })
      const { data } = response
      dispatch(getSectorPublicDashboardsSuccess(data))
      return data
    } catch (error) {
      dispatch(getSectorPublicDashboardsFailure(error))
      return null
    }
  }
}

// GET SECTOR PRIVATE DASHBOARDS ACTION
export function getSectorPrivateDashboards(sectorId) {
  return async dispatch => {
    dispatch(getSectorPrivateDashboardsRequest())
    try {
      const response = await http({
        method: 'GET',
        url: `/sector/${sectorId}/dashboards/private`
      })
      const { data } = response
      dispatch(getSectorPrivateDashboardsSuccess(data))
      return data
    } catch (error) {
      dispatch(getSectorPrivateDashboardsFailure(error))
      return null
    }
  }
}

// GET USER PUBLIC DASHBOARDS ACTION
export function getUserPublicDashboards() {
  return async dispatch => {
    dispatch(getUserPublicDashboardsRequest())
    try {
      const response = await http({
        method: 'GET',
        url: '/dashboards/public'
      })
      console.log(response?.data)
      const data = response?.data?.map(dashboard => {
        const [[sector, dashboards]] = Object.entries(dashboard)
        return {
          sector, dashboards
        }
      })
      dispatch(getUserPublicDashboardsSuccess(data))
      return data
    } catch (error) {
      dispatch(getUserPublicDashboardsFailure(error))
      return null
    }
  }
}

// GET USER PRIVATE DASHBOARDS ACTION
export function getUserPrivateDashboards() {
  return async dispatch => {
    dispatch(getUserPrivateDashboardsRequest())
    try {
      const response = await http({
        method: 'GET',
        url: '/dashboards/private'
      })
      const data = response?.data?.map(dashboard => {
        const [[sector, dashboards]] = Object.entries(dashboard)
        return {
          sector, dashboards
        }
      })
      dispatch(getUserPrivateDashboardsSuccess(data))
      return data
    } catch (error) {
      dispatch(getUserPrivateDashboardsFailure(error))
      return null
    }
  }
}

// UPDATE ACTION
export function updateDashboard(values) {
  return async dispatch => {
    dispatch(updateDashboardRequest())
    try {
      const response = await http({
        method: 'PUT',
        url: `/dashboard/${values.id}`,
        data: {
          name: values.dashboardName,
          report_id: values.reportId,
          workspace_id: values.workspaceId,
          platform: values.platformId,
          is_private: values.isPrivate,
          sector_id: values.sectorId,
          owner: values.ownerId,
          users: values.users
        }
      })
      dispatch(updateDashboardSuccess(response))
      return response.status
    } catch (error) {
      dispatch(updateDashboardFailure(error))
      return null
    }
  }
}

// DELETE ACTION
export function deleteDashboard(id) {
  return async dispatch => {
    dispatch(deleteDashboardRequest())
    try {
      const response = await http({
        method: 'DELETE',
        url: `/dashboard/${id}`
      })
      dispatch(deleteDashboardSuccess(response))
      return response.status
    } catch (error) {
      dispatch(deleteDashboardFailure(error))
      return null
    }
  }
}

// GET EMBED TOKEN ACTION
export function getEmbedToken(dashboardId) {
  return async dispatch => {
    dispatch(getEmbedTokenRequest())
    try {
      const response = await http({
        method: 'GET',
        url: `/embed-report/${dashboardId}`
      })
      const data = response?.data
      dispatch(getEmbedTokenSuccess(data))
      return data
    } catch (error) {
      dispatch(getEmbedTokenFailure(error))
      return null
    }
  }
}
