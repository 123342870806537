import React, { useEffect } from 'react'
import * as pbi from 'powerbi-client'
import { useDispatch, useSelector } from 'react-redux'
import { getEmbedToken } from '../../../store/actions/dashboard'
import { DashboardContent } from './styles'

const Dashboard = (match) => {
  const dispatch = useDispatch()
  const dashboardId = match.match?.params?.dashboardId
  const { embedData } = useSelector(state => state.dashboard)

  useEffect(() => {
    dispatch(getEmbedToken(dashboardId))
  }, [dashboardId, dispatch])

  useEffect(() => {
    const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory)

    if (powerbi && embedData) {
      const reportContainer = document.getElementById('dashboardContainer')

      const embedConfiguration = {
        ...embedData,
        tokenType: pbi.models.TokenType.Embed,
        type: 'report'
      }

      const newSettings = {
        panes: {
          filters: {
            visible: false
          }
        }
      }

      // If you have embedded a report within an element and want to reset the element back to its initial state,
      // call: powerbi.reset(element); This method removes the embed from the service and removes the iframe
      // (required to prevent the service from holding on to reference that doesn't exist in the DOM).
      // You typically need to call reset before the containing element is removed from the DOM by the parent
      powerbi.reset(reportContainer)
      powerbi.embed(reportContainer, embedConfiguration).updateSettings(newSettings)
    }
  }, [embedData, dashboardId])

  return (
    <DashboardContent id="dashboardContainer" />
  )
}

export default Dashboard
